export enum PlayerCamp {
    none = "0",
    plane = "1",
    bird = "2",

    blue = "9",
    red = "8",



}

export const PlayerCampName: {[key: string]: string} = {
    [PlayerCamp.bird]: "小鸟",
    [PlayerCamp.plane]: "飞机",
    [PlayerCamp.blue]: "蓝",
    [PlayerCamp.red]: "红",

    "xhzb-8": "飞机",
    "xhzb-9": "小鸟",
    "xyxm-1": "神灵",
    "xyxm-2": "妖怪",

}

export enum DanmuCmd {
    Camp = "选择阵营",
    Like = "点赞",

    Wand = "仙女棒",
    Pill = "能力药丸",
    Mirror = "魔法镜",
    Doughnut = "甜甜圈",
    Battery = "能量电池",
    Bomb = "恶魔炸弹",
    Airdrop = "神秘空投",
    Superdrop = "超级空投",
    Emission = "超能喷射",

    Torch = "助威火炬/仙女棒",
    Fire = "火花/甜甜圈",
    Ice = "雪糕刺客/能量电池",
    Duck = "冲鸭/恶魔炸弹",
    Romance = "浪漫满屏/神秘空投",
    Laotie = "老铁666/超能喷射",
    HeartJet = "爱心喷射机/超能喷射",
    FullOpen = "火力全开/超能喷射",
    TubiNo1 = "兔比No.1/超能喷射",
    Rocket = "助威火箭/能量电池",
    Leaf = "一叶知秋/甜甜圈",

    Custom = "自定义"
}

export enum DanmuType {
    Camp = "阵营",
    Like = "点赞",
    Gift = "礼物",
    Custom = "自定义",
}

export const GiftCategory: {[p: string]: DanmuCmd[]} = {
    douyin: [
        DanmuCmd.Wand,
        DanmuCmd.Doughnut,
        DanmuCmd.Battery,
        DanmuCmd.Bomb,
        DanmuCmd.Airdrop,
        DanmuCmd.Emission,

        DanmuCmd.Pill,
        DanmuCmd.Mirror,
    ],
    kuaishou: [
        DanmuCmd.Torch,
        DanmuCmd.Fire,
        DanmuCmd.Ice,
        DanmuCmd.Duck,
        DanmuCmd.Romance,
        DanmuCmd.Laotie,
        DanmuCmd.HeartJet,
        DanmuCmd.FullOpen,
        DanmuCmd.TubiNo1,
        DanmuCmd.Rocket,
        DanmuCmd.Leaf,
    ]
}

export const GiftIdMap = new Map<DanmuCmd, string>(
    [
        [DanmuCmd.Wand, "n1/Dg1905sj1FyoBlQBvmbaDZFBNaKuKZH6zxHkv8Lg5x2cRfrKUTb8gzMs="],
        [DanmuCmd.Pill, "28rYzVFNyXEXFC8HI+f/WG+I7a6lfl3OyZZjUS+CVuwCgYZrPrUdytGHu0c="],
        [DanmuCmd.Mirror, "fJs8HKQ0xlPRixn8JAUiL2gFRiLD9S6IFCFdvZODSnhyo9YN8q7xUuVVyZI="],
        [DanmuCmd.Doughnut, "PJ0FFeaDzXUreuUBZH6Hs+b56Jh0tQjrq0bIrrlZmv13GSAL9Q1hf59fjGk="],
        [DanmuCmd.Battery, "IkkadLfz7O/a5UR45p/OOCCG6ewAWVbsuzR/Z+v1v76CBU+mTG/wPjqdpfg="],
        [DanmuCmd.Bomb, "gx7pmjQfhBaDOG2XkWI2peZ66YFWkCWRjZXpTqb23O/epru+sxWyTV/3Ufs="],
        [DanmuCmd.Airdrop, "pGLo7HKNk1i4djkicmJXf6iWEyd+pfPBjbsHmd3WcX0Ierm2UdnRR7UINvI="],
        [DanmuCmd.Superdrop, "lsEGaeC++k/yZbzTU2ST64EukfpPENQmqEZxaK9v1+7etK+qnCRKOnDyjsE="],
        [DanmuCmd.Emission, "P7zDZzpeO215SpUptB+aURb1+zC14UC9MY1+MHszKoF0p5gzYk8CNEbey60="],

        [DanmuCmd.Torch, "11582"],
        [DanmuCmd.Fire, "11584"],
        [DanmuCmd.Ice, "11585"],
        [DanmuCmd.Duck, "11586"],
        [DanmuCmd.Romance, "11587"],
        [DanmuCmd.Laotie, "11588"],
        [DanmuCmd.HeartJet, "11589"],
        [DanmuCmd.FullOpen, "11590"],
        [DanmuCmd.TubiNo1, "11591"],
        [DanmuCmd.Rocket, "11606"],
        [DanmuCmd.Leaf, "12252"]
    ]
)

